.formWrapper {
    margin: 25px 0 25px 0;
    text-align: center;
    border-radius: 25px;
    background: #87CEEB;
    width: 100%;
    box-shadow: 3px 4px #888888;
}

.formWrapper form {
    width: 75%;
    margin: auto;
}

.formWrapper h2 {
    color: white;
}