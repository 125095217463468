.products-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.products-list {
    list-style-type: none;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.products-list li {
    margin: 25px 0 25px 0;
    text-align: center;
    border-radius: 25px;
    background: #87CEEB;
    width: 80%;
    box-shadow: 3px 4px #888888;
}

.products-list img {
   width: 90%;
}

.product-icon {
    width: 3%;
    height: auto;
    cursor: pointer;
    margin: 0 20px 15px 20px;
    fill: rgb(37, 182, 210);
}

.delete-message {
    font-size: 20px;
}

.form-buttons-container{
    display: flex;
    justify-content: center;
}

.product-form-wrapper{
    width: 70%;
    margin: auto;
}