.NavigationItem{
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    font-size: 20px;
    white-space: nowrap;
}

.NavigationItem a{
    color: #008080;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active{
    color: #20B2AA;
}

@media (min-width: 963px){
    .NavigationItem{
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }

    .NavigationItem a{
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }

    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active{
        background-color: #4682B4;
        color: white;
    }
}