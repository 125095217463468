.location-container {
    color: white;
    float: left;
    list-style-type: none;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 0 20px 20px;
}


@media (max-width: 963px){
    .location-container{
        width: 88%;
        margin: auto;
    }
}

.location-container h2 {
    text-align: center;
}

.location-container iframe {
    float: left;
    width: 100%;
    height: 287px;
    border: 0;
}