.slide-container {
    width: 100%;
    margin: auto;
}

.slide-container-small{
    width: 50%;
    margin-top: 20px;
}

.each-slide > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: calc(0.5625*100vw);
}

.each-slide-small > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: calc(0.5625*50vw);
    border-radius: 25px;
}

.texts {
    font-size: 250%;
    color: white;
    font-weight: bolder;
    cursor: default;
    user-select: none;
    text-shadow: 1px 1px #06080c;
}

.subtitles {
    font-size: 150%;
    color: white;
    font-weight: bolder;
    cursor: default;
    user-select: none;
    text-shadow: 1px 1px #06080c;
}

@media (max-width: 963px){
   .texts{
       font-size: 180%;
   }

   .subtitles {
        font-size: 100%;
        text-align: center;
    }

   .slide-container-small{
        width: 80%;
    }

    .each-slide-small > div {
        height: calc(0.5625*80vw);
    }
}