.AboutWrapper{
    min-height: calc(100vh - 406px);
    background-color: #a6c3e0;
    display: flex;
    justify-content: center;
}

.FirstRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ParagraphWrapper{
    width: 40%;
    text-align: left;
    color: white;
    font-size: larger;
    margin: 30px;
}

.Title{
    color: #0281bf;
}

.BuildingFrontImage{
    border-radius: 25px;
    margin: 30px;
    width: 54%;
}

@media(max-width: 963px){
    .AboutWrapper{
        flex-direction: column;
    }

    .ParagraphWrapper{
        width: 88%;
        text-align: center;
        margin-bottom: 0;
    }

    .FirstRow{
        flex-direction: column;
    }

    .BuildingFrontImage{
        width: 88%;
    }
}