.Wrapper{
    background-color: #778899;
    min-height: calc(100vh - 56px);
    display: flex;
}

.Auth{
    text-align: center;
    width: 25%;
    margin: auto;
    background: #87CEEB;
    border-radius: 25px;
}