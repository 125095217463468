.footer-container {
    background-color: #87ceeb;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.information-container{
    display: flex;
    flex-direction: row;
}

.copywrite-disclaimer{
    margin: 0;
    color: white;
    text-align: center;
    margin-bottom: 15px;
}

@media (max-width: 963px){
    .information-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-container{
        height: 100%;
    }
}
