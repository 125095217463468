.Toolbar{
    height: 56px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #87CEEB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar nav{
    height: 100%;
    display: flex;
}

.Logo {
    height: 2.7vw;
}

.Title {
    color: white;
}

@media (max-width: 963px){
    .DesktopOnly{
        display: none;
    }

    .Logo {
        height: 50%;
    }
}

.Icon{
    width: 20px;
    margin-left: 5px;
    cursor: pointer;
}