.contact-container{
    background-color: #a6c3e0;
    position: absolute;
    top: 56px; right: 0; left: 0;
    color: white;
    min-height: calc(100vh - 56px);
}

.contact-container img {
    max-width: 100%;
    height: auto;
}

.location-wrapper{
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
}

.text{
    width: 28%;
    margin: 20px;
 }

.contact-wrapper{
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
}

.form-wrapper{
    width: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.copywrite-disclaimer{
    margin: 0;
    color: white;
    text-align: center;
    margin-bottom: 15px;
}

@media (max-width: 963px){
    .location-wrapper{
        flex-direction: column;
    }

    .text{
        width: 90%;
        text-align: center;
    }

    .contact-wrapper{
        flex-direction: column;
    }

    .form-wrapper{
        width: 100%;
    }
}