.formWrapper {
    text-align: center;
}

.formWrapper form {
    width: 75%;
    margin: auto;
}

.formWrapper h2 {
    color: white;
}