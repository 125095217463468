.works-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 406px);
    background-color: #a6c3e0;
}

.categories-container{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0 1px 0 1px;
}

.categories-container-selected {
    display: flex;
    flex-direction: column;
    background-color: #a6c3e0;
}

.empty-state{
    color: white;
    padding-top: 50px;
    font-size: 28px;
    text-align: center;
}

.link-to-self{
    text-decoration: none;
    color: white;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 250px;
    margin: 0 1px 0 1px;
}

.category-form-wrapper{
    margin: 25px 0 25px 0;
    text-align: center;
    border-radius: 25px;
    background: #87CEEB;
    width: 76%;
    box-shadow: 3px 4px #888888;
}

.back-icon {
    width: 4%;
    height: auto;
    cursor: pointer;
    margin: auto;
    fill: rgb(37, 182, 210);
    margin: 15px auto 15px auto;
}

@media (max-width: 963px){
    .back-icon {
        width: 15%;
    }
}