.Wrapper{
    background-color: #a6c3e0;
}

.Title {
    text-align: center;
    color: white;
    background-color: #0281bf;
    margin-top: 0;
    padding: 5px 0 5px 0;
}

.Link {
    text-decoration: none;
    color: white;
    font-size: 20px;
    text-align: center;
    display: block;
    font-weight: bold;
    padding-bottom: 7px;
}
