.ProductsWrapper{
    min-height: calc(100vh - 406px);
    background-color: #a6c3e0;
}

.FirstRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ParagraphWrapper{
    width: 40%;
    text-align: left;
    color: white;
    font-size: larger;
    margin: 30px;
}

.Title{
    color: #0281bf;
}

.Link {
    text-decoration: none;
    color: white;
    font-weight: bold;
}

@media(max-width: 963px){
    .FirstRow{
        flex-direction: column;
    }

    .ParagraphWrapper{
        width: 88%;
        margin-bottom: 0;
    }
}