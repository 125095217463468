.address-container {
    color: white;
    float: left;
    width: 45%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 963px){
    .address-container{
        width: auto;
        margin-bottom: 20px;
    }
}

.address-container h2 {
    margin: 20px 0 0 20px;
    text-align: center;
}

.address-container ul{
    list-style-type: none;
    padding-inline-start: 0;
}

.address-container li {
    font-style: normal;
    font-size: 16px;
    font-weight: bold;
    height: 35px;
    border-bottom: solid #008B8B 2px;
    margin-top: 9px;
    width: 285px;
 }

 .address-container a {
     color: white;
     text-decoration: none;
 }

.address-container a:hover{
     color: #DCDCDC;
     cursor: pointer;
 }

.links{
    display: flex;
    flex-direction: row;
}

.logo{
    fill: white;
    width: 25px;
    height: 25px;
    margin: 0 10px 0 10px;
}

.logo:hover{
    fill: #DCDCDC;
    cursor: pointer;
}
