.product-container{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 250px;
    margin: 50px 1px 35px 1px;
}

.product-container-expanded{
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 10px 0 0;
    background-color: #0281bf;
    cursor: pointer;
}

.category-title{
    width: 100%;
    min-height: 54px;
    cursor: pointer;
    text-align: center;
    color: #0281bf;
    font-size: 20px;
    font-weight: bold;
}

.category-title-expanded{
    cursor: pointer;
    margin-left: 25px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    font-weight: bold;
}

.product-img {
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
    cursor: pointer;
}

.icon {
    width: 7%;
    height: auto;
    cursor: pointer;
    margin: 8px 20px 0 20px;
    fill: rgb(37, 182, 210);
}

.buttons-container{
    display: flex;
    justify-content: center;
}

.delete-category-message{
    font-size: 20px;
    color: white;
}

.modal-wrapper{
    text-align: center;
}